import './kili.scss';

function nodeListToArray(nl) {
  var arr = [];
  for (var i = 0, ref = (arr.length = nl.length); i < ref; i++) {
    arr[i] = nl[i];
  }
  return arr;
}

(function (funcName, baseObj) {
  "use strict";
  funcName = funcName || "docReady";
  baseObj = baseObj || window;
  var readyList = [];
  var readyFired = false;
  var readyEventHandlersInstalled = false;

  function ready() {
    if (!readyFired) {
      readyFired = true;
      for (var i = 0; i < readyList.length; i++) {
        readyList[i].fn.call(window, readyList[i].ctx);
      }
      readyList = [];
    }
  }

  function readyStateChange() {
    if (document.readyState === "complete") {
      ready();
    }
  }

  baseObj[funcName] = function (callback, context) {
    if (typeof callback !== "function") {
      throw new TypeError("callback for docReady(fn) must be a function");
    }
    if (readyFired) {
      setTimeout(function () {
        callback(context);
      }, 1);
      return;
    } else {
      readyList.push({
        fn: callback,
        ctx: context
      });
    }
    if (document.readyState === "complete" || (!document.attachEvent && document.readyState === "interactive")) {
      setTimeout(ready, 1);
    } else if (!readyEventHandlersInstalled) {
      if (document.addEventListener) {
        document.addEventListener("DOMContentLoaded", ready, false);
        window.addEventListener("load", ready, false);
      } else {
        document.attachEvent("onreadystatechange", readyStateChange);
        window.attachEvent("onload", ready);
      }
      readyEventHandlersInstalled = true;
    }
  }
})("docReady", window);

function getMenuElements() {
  return nodeListToArray(document.querySelectorAll('.overlay, .menu'));
}
window.docReady(() => {
  function closeMenu() {
    getMenuElements().forEach(e => e.classList.remove('open'));
  }

  function openMenu() {
    getMenuElements().forEach(e => e.classList.add('open'));
  }

  function toggleMenu() {
    var isOpen = document.querySelector('.menu.open');
    isOpen ? closeMenu() : openMenu();
  }
  document.querySelector('.overlay').addEventListener('click', closeMenu)

  nodeListToArray(document.querySelectorAll('.menu-button, .open-menu')).forEach(el => el.addEventListener('click', toggleMenu));
})
